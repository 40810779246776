.Pisogenericas-p > div { 
   position: relative; 
}
.Pisogenericas-parent { 
	max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 0rem;
}

.primer-bloque {
	display: grid;
    grid-template-columns: repeat(4, 300px);
    grid-gap: 2rem;
    gap: 2rem;
    grid-area: 2 / 1 / auto / 3;
       
}
.segundo-bloque {
	grid-area: 2 / 4 / auto / 5;
    grid-gap: 2rem;
    gap: 2rem;
    display: grid;
}
.grid-item {
	padding-bottom: 0.8rem;
	border-bottom: 1px solid var(--light-gray);
}
.container-banner-chico{
    width: 300px;
    height: 250px;
    margin: 0 auto;
    min-width: 100%;
    text-align: center;
    vertical-align: middle;
    background: repeating-linear-gradient(45deg, hsla(0, 0%, 90%, .1), hsla(0, 0%, 80%, .1), hsla(0, 0%, 90%, .1), hsla(0, 0%, 80%, .1) 4px, rgba(0, 0, 0, .15) 6px);
}
.lo-ultimo{
    display: grid;
    width: 300px;
    height: 450px;
    margin: 0 auto;
    min-width: 100%;
    text-align: center;
    vertical-align: middle;
    background: var(--light-gray);
}
/*nuevos*/
.contenedor-gral-tittulo-container { border-bottom:1px solid #E6E6E6; margin:0 0 20px 0; display: flex; align-items: center;}
.contenedor-gral-tittulo-container h2{color:var(--dark-black); text-transform: uppercase; padding: 0; margin:0 10px 0 0; text-decoration: none;}
.contenedor-gral-tittulo-container h2 a {color:var(--dark-black); text-transform: uppercase; padding: 0; margin:0 10px 0 0; text-decoration: none;}
.contenedor-gral-tittulo-container .contenedor-left-titulo {background-color:#ffffff; padding:0px; float: left;}
.contenedor-left-titulo img {max-height: 45px;}
.menu-el-scroll { padding: 12px 0px 12px 10px; white-space: nowrap; overflow-x: hidden; scroll-behavior: inherit; display: block; overflow-y: hidden;}
.menu-el-scroll ul { margin: 0; padding: 0!important;}
.menu-el-scroll li { display: inline; margin: 0px 8px 0 2px; padding: 7px; font-weight: 400; font-size: 0.8rem; background-color: #eee; color: #555555; padding: 7px 14px 7px 14px; border-radius: 14px;}    
.menu-el-scroll li a { color: #555555!important; text-decoration: none;}  
.scroll-margin { margin-bottom: 10px;}
.contenedor-secundario-container {float: left; margin: 0 1rem 0 0}
.menu-el-scroll li .com-link { white-space: nowrap; position: relative;}
.menu-el-scroll .com-link:after { content: "•"; position: absolute; color: #ccc; right: -1rem;}
.sponsorHead {max-width: 300px; max-height: 40px; overflow: hidden; background-color: aquamarine;}
  
@media (max-width: 1024px) {
.primer-bloque {
	display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-gap: 2rem;
    gap: 2rem;
    grid-area: 2 / 1 / auto / 3;
 }
}

@media (max-width: 768px) {
    .Pisogenericas-parent { 
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
	.Piso1Notas-parent {
		grid-template-columns: 1fr;
	}
	.primer-bloque {
		padding-bottom: 2.2rem;
        grid-template-columns: 1fr;
        grid-area: auto;
	}
    .segundo-bloque {
		padding-bottom: 2.2rem;
        grid-template-columns: 1fr;
        grid-area: auto;
	}
    .container-banner-chico{
        position: 4;
    }
    /*NUEVOS*/
    .contenedor-secundario-container {width:100%;}
    .contenedor-secundario2-container {width:100%;}    
    .menu-el-scroll {clear: both; padding:0.9rem 0rem 0.9rem 0rem; white-space: nowrap; overflow-x: hidden; scroll-behavior: inherit; display: block; overflow: scroll; overflow-y: hidden;}
	.contenedor-gral-tittulo-container {margin:1rem 0; height:auto; display: block;}
	.contenedor-gral-tittulo-container .contenedor-left-titulo h2 {margin:0rem;}
    .contenedor-gral-tittulo-container .contenedor-left-titulo {padding-top:0px;}
}

